define("discourse/plugins/discourse-post-voting/discourse/widgets/post-voting-button-shim", ["discourse/widgets/render-glimmer", "@ember/template-factory"], function (_renderGlimmer, _templateFactory) {
  "use strict";

  (0, _renderGlimmer.registerWidgetShim)("post-voting-button", "div.post-voting-button-shim", (0, _templateFactory.createTemplateFactory)(
  /*
    <PostVotingButton
      @direction={{@data.direction}}
      @loading={{@data.loading}}
      @voted={{@data.voted}}
      @removeVote={{@data.removeVote}}
      @vote={{@data.vote}}
      @disabled={{@data.disabled}}
    />
  */
  {
    "id": "moBAkoKp",
    "block": "[[[8,[39,0],null,[[\"@direction\",\"@loading\",\"@voted\",\"@removeVote\",\"@vote\",\"@disabled\"],[[30,1,[\"direction\"]],[30,1,[\"loading\"]],[30,1,[\"voted\"]],[30,1,[\"removeVote\"]],[30,1,[\"vote\"]],[30,1,[\"disabled\"]]]],null]],[\"@data\"],false,[\"post-voting-button\"]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/discourse-post-voting/discourse/widgets/post-voting-button-shim.js",
    "isStrictMode": false
  }));
});